import { useEffect } from 'react';

import { type EnumsPageName } from '@lp-lib/api-service-client/public';

import { type GameLike } from '../../../types/game';
import { type Tag, virtualTags } from '../../../types/tag';
import {
  type GameLikeClickedProps,
  type GameLikeContext,
  useGameLikeFiltersAsQueryString,
  type WritableEmbedContext,
} from './Context';

type PickerViewMap<T extends GameLike> = {
  MyGameLikeList: (props: GameLikeClickedProps<T>) => JSX.Element;
  TagGameLikeList: (
    props: GameLikeClickedProps<T> & { tag?: Tag }
  ) => JSX.Element | null;
  UntaggedGameLikeList: (props: GameLikeClickedProps<T>) => JSX.Element;
  SearchGameLikeList: (
    props: GameLikeClickedProps<T> & {
      search?: string;
      scope?: EnumsPageName;
      primeOnly?: boolean;
    }
  ) => JSX.Element | null;
  MyGameLikes: (props: GameLikeClickedProps<T>) => JSX.Element;
  DiscoveryLibrary: (props: GameLikeClickedProps<T>) => JSX.Element | null;
  PublicLibrary?: (props: GameLikeClickedProps<T>) => JSX.Element | null;
  PlayAgainGameLikeList?: (props: GameLikeClickedProps<T>) => JSX.Element;
};

export type PublicPickerProps<T extends GameLike> = {
  primeOnly?: boolean;
  scope?: EnumsPageName;
  onItemClick?: (item: T) => void;
};

type PickerProps<T extends GameLike> = PublicPickerProps<T> & {
  type: T['type'];
  ctx: GameLikeContext<T>;
};

export function GoBack(props: {
  updateEmbedCtx: (ctx: WritableEmbedContext | null) => void | null;
}): JSX.Element {
  const handleBack = () => {
    if (!props.updateEmbedCtx) return;
    props.updateEmbedCtx(null);
  };
  return (
    <button
      type='button'
      className='btn text-xl hover:text-white'
      onClick={handleBack}
    >
      Back
    </button>
  );
}

function SearchGameLikeListWrapped<T extends GameLike>(
  props: PickerProps<T> & {
    search?: string;
    view: PickerViewMap<T>['SearchGameLikeList'];
  }
): JSX.Element {
  const SearchGameLikeList = props.view;
  return (
    <SearchGameLikeList
      search={props.search}
      primeOnly={props.primeOnly}
      scope={props.scope}
      onItemClick={props.onItemClick}
    />
  );
}

function GameLikePickerEmbed<T extends GameLike>(
  props: PickerProps<T> & {
    viewMap: PickerViewMap<T>;
  }
): JSX.Element | null {
  const ctx = props.ctx;
  const {
    MyGameLikeList,
    TagGameLikeList,
    UntaggedGameLikeList,
    SearchGameLikeList,
    MyGameLikes,
    DiscoveryLibrary,
    PublicLibrary,
    PlayAgainGameLikeList,
  } = props.viewMap;
  const search = ctx.embed ? ctx.embedCtx?.search : undefined;
  const filtersAsQueryString = useGameLikeFiltersAsQueryString(
    props.type,
    search
  );
  const updateEmbedCtx = ctx.embed ? ctx.updateEmbedCtx : null;

  useEffect(() => {
    if (!updateEmbedCtx) return;
    if (filtersAsQueryString.length > 0) {
      updateEmbedCtx({ search: filtersAsQueryString });
    }
  }, [filtersAsQueryString, updateEmbedCtx]);

  useEffect(() => {
    if (!updateEmbedCtx) return;
    ctx.breadcrumbs.add('back', <GoBack updateEmbedCtx={updateEmbedCtx} />);
    return () => {
      ctx.breadcrumbs.remove('back');
    };
  }, [ctx.breadcrumbs, updateEmbedCtx]);

  useEffect(() => {
    if (!updateEmbedCtx) return;
    return () => {
      updateEmbedCtx(null);
    };
  }, [updateEmbedCtx]);

  if (!ctx.embed) return null;

  if (ctx.embedCtx.tag) {
    switch (ctx.embedCtx.tag.id) {
      case virtualTags.my.id:
        return <MyGameLikeList onItemClick={props.onItemClick} />;
      case virtualTags.untagged.id:
        return <UntaggedGameLikeList onItemClick={props.onItemClick} />;
      case virtualTags.playAgain.id:
        if (PlayAgainGameLikeList) {
          return <PlayAgainGameLikeList onItemClick={props.onItemClick} />;
        }
        return null;
      default:
        return (
          <TagGameLikeList
            onItemClick={props.onItemClick}
            tag={ctx.embedCtx.tag}
          />
        );
    }
  }
  if (ctx.embedCtx.search) {
    return (
      <SearchGameLikeListWrapped
        {...props}
        search={ctx.embedCtx.search}
        view={SearchGameLikeList}
      />
    );
  }
  if (ctx.pageType === 'public' && PublicLibrary) {
    return <PublicLibrary onItemClick={props.onItemClick} />;
  }

  return (
    <div className='w-full h-full'>
      {!props.primeOnly && <MyGameLikes onItemClick={props.onItemClick} />}
      <DiscoveryLibrary onItemClick={props.onItemClick} />
    </div>
  );
}

export function GameLikePicker<T extends GameLike>(
  props: PickerProps<T> & {
    viewMap: PickerViewMap<T>;
  }
): JSX.Element {
  return <GameLikePickerEmbed {...props} />;
}
