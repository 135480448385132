import {
  GameLikePicker,
  type PublicPickerProps,
} from '../../components/Game/GameCenter/Picker';
import { type Game } from '../../types/game';
import { useMinigameContext } from './Context';
import { Library } from './Library';
import {
  MyGameList,
  SearchGameList,
  TagGameList,
  UntaggedGameList,
} from './List';
import { MyGames } from './MyGames';

export function GamePicker(props: PublicPickerProps<Game>): JSX.Element {
  const ctx = useMinigameContext();
  return (
    <GameLikePicker
      {...props}
      type='game'
      ctx={ctx}
      viewMap={{
        MyGameLikeList: MyGameList,
        TagGameLikeList: TagGameList,
        UntaggedGameLikeList: UntaggedGameList,
        SearchGameLikeList: SearchGameList,
        MyGameLikes: MyGames,
        DiscoveryLibrary: Library,
      }}
    />
  );
}
